import React from 'react'
import Header from './Header'
import Content from './Content'
import Footer from './Footer'
import axios from 'axios';
function Home() {
  const [data,setData] = React.useState([])
  const [data2,setData2] = React.useState([])
  const getResults = async () => {
    try {
        let res = await axios.get(
            `/public/markets?tag=global`
        );
        let resData = res.data.data;
        // console.log(resData)
        setData(resData)
    } catch (e) {
        console.log("e", e);
    }
};
const getResults2 = async () => {
  try {
      let res = await axios.get(
          `/public/links`
      );
      let resData = res.data;
      // console.log(resData)
      setData2(resData)
  } catch (e) {
      console.log("e", e);
  }
};

  React.useEffect(()=>{
    getResults()
    getResults2()
},[])
  return (
    <>
    <Header apk={data2.app_link}/>
    <Content data2={data2} />
    <Footer/>
    </>
  )
}

export default Home