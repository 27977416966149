import React from 'react';
import { Box, Typography, Container, Divider, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import img from '../assets/images/section-top.svg';
import img2 from '../assets/images/section-bottom.svg';



function Pana({data}) {
    return (
        <>
            <img src={img} style={{ background: '#F6F6F6', width: '100%' }} alt="Top Section" />
            <Box sx={{ background: '#F6F6F6', pt: 3, marginTop: '-7px' }}>
                <Container maxWidth="lg">
                    <Typography variant="h4" component="h1" align="center" gutterBottom>
                        Pana Chart
                    </Typography>
                    {data.map((item, index) => (
                        <Paper key={index} sx={{
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minHeight: '80px',
                            boxShadow: '0px 25px 65px rgba(0, 0, 0, 0.10)'
                        }}>
                            <Divider orientation="vertical" sx={{
                                position: 'absolute',
                                left: 0,
                                top: '5px',
                                bottom: '5px',
                                borderRightWidth: '5px',
                                borderColor: '#FFDD00',
                                height: 'auto'
                            }} />
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Link 
                                    to={`/panachart/${item._id}`}
                                    style={{
                                        color: '#000',
                                        textDecoration: 'none',
                                        fontSize: '23px',
                                        fontWeight: '500'
                                    }}
                                >
                                    {item.name}
                                </Link>
                            </Box>
                        </Paper>
                    ))}
                </Container>
            </Box>
            <img src={img2} style={{ background: '#F6F6F6', width: '100%' }} alt="Bottom Section" />
        </>
    );
}

export default Pana;
