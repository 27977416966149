import React, { useEffect, useState } from "react";
import img from "../assets/images/section-top.svg";
import img2 from "../assets/images/section-bottom.svg";

import { Box, Typography, Paper, Divider } from "@mui/material";
import axios from "axios";

function GameRates() {
  const [data, setData] = useState([]);

  const getResults = async () => {
    try {
      let res = await axios.get(`/public/setting`);
      let resData = res.data.data;
      setData(resData);
    } catch (e) {
      console.log("e", e);
    }
  };
  useEffect(() => {
    getResults();
  }, []);
  return (
    <>
      <img src={img} style={{ background: "#F6F6F6", width: "100%" }} />
      <Box
        sx={{
          background: "#F6F6F6",
          marginTop: "-7px",
          padding: "20px",
        }}
      >
        <Box
          sx={{
            width: { xs: "90%", md: "70%", sm: "90%" },
            margin: "auto",
          }}
        >
          <Typography variant="h4" style={{ fontWeight: "700" }} gutterBottom>
            Game Rates
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            
            justifyContent:'center',
            width: { xs: "90%", md: "90%", sm: "90%" },
            margin: "auto",
            gap: "20px",
          }}
        >
          <Paper sx={{ padding: "20px", width: "300px", position: "relative" }}>
            <Divider
              orientation="vertical"
              sx={{
                position: "absolute",
                left: 0,
                top: "5px",
                bottom: "10px",
                borderRightWidth: "5px",
                borderColor: "#FFDD00",
                height: "90px",
              }}
            />
            <Box sx={{}}>
              <Typography variant="h6" gutterBottom>
                Single Digit
              </Typography>
              <Typography variant="body2">
                {data?.rates?.global?.single_digit_1} Rs Ka{" "}
                {data?.rates?.global?.single_digit_2} Rs
              </Typography>
            </Box>
          </Paper>
          <Paper sx={{ padding: "20px", width: "300px", position: "relative" }}>
            <Divider
              orientation="vertical"
              sx={{
                position: "absolute",
                left: 0,
                top: "5px",
                bottom: "10px",
                borderRightWidth: "5px",
                borderColor: "#FFDD00",
                height: "90px",
              }}
            />
            <Box sx={{}}>
              <Typography variant="h6" gutterBottom>
                Jodi Digit
              </Typography>
              <Typography variant="body2">
                {data?.rates?.global?.jodi_digit_1} Rs Ka{" "}
                {data?.rates?.global?.jodi_digit_2} Rs
              </Typography>
            </Box>
          </Paper>
          <Paper sx={{ padding: "20px", width: "300px", position: "relative" }}>
            <Divider
              orientation="vertical"
              sx={{
                position: "absolute",
                left: 0,
                top: "5px",
                bottom: "10px",
                borderRightWidth: "5px",
                borderColor: "#FFDD00",
                height: "90px",
              }}
            />
            <Box sx={{}}>
              <Typography variant="h6" gutterBottom>
                Single Pana
              </Typography>
              <Typography variant="body2">
                {data?.rates?.global?.single_pana_1} Rs Ka{" "}
                {data?.rates?.global?.single_pana_2} Rs
              </Typography>
            </Box>
          </Paper>
          <Paper sx={{ padding: "20px", width: "300px", position: "relative" }}>
            <Divider
              orientation="vertical"
              sx={{
                position: "absolute",
                left: 0,
                top: "5px",
                bottom: "10px",
                borderRightWidth: "5px",
                borderColor: "#FFDD00",
                height: "90px",
              }}
            />
            <Box sx={{}}>
              <Typography variant="h6" gutterBottom>
                Double Pana
              </Typography>
              <Typography variant="body2">
                {data?.rates?.global?.double_pana_1} Rs Ka{" "}
                {data?.rates?.global?.double_pana_2} Rs
              </Typography>
            </Box>
          </Paper>
          <Paper sx={{ padding: "20px", width: "300px", position: "relative" }}>
            <Divider
              orientation="vertical"
              sx={{
                position: "absolute",
                left: 0,
                top: "5px",
                bottom: "10px",
                borderRightWidth: "5px",
                borderColor: "#FFDD00",
                height: "90px",
              }}
            />
            <Box sx={{}}>
              <Typography variant="h6" gutterBottom>
                Triple Pana
              </Typography>
              <Typography variant="body2">
                {data?.rates?.global?.tripple_pana_1} Rs Ka{" "}
                {data?.rates?.global?.tripple_pana_2} Rs
              </Typography>
            </Box>
          </Paper>

          <Paper sx={{ padding: "20px", width: "300px", position: "relative" }}>
            <Divider
              orientation="vertical"
              sx={{
                position: "absolute",
                left: 0,
                top: "5px",
                bottom: "10px",
                borderRightWidth: "5px",
                borderColor: "#FFDD00",
                height: "90px",
              }}
            />
            <Box sx={{}}>
              <Typography variant="h6" gutterBottom>
                Half Sangam
              </Typography>
              <Typography variant="body2">
                {data?.rates?.global?.half_sangam_1} Rs Ka{" "}
                {data?.rates?.global?.half_sangam_2} Rs
              </Typography>
            </Box>
          </Paper>
          <Paper sx={{ padding: "20px", width: "300px", position: "relative" }}>
            <Divider
              orientation="vertical"
              sx={{
                position: "absolute",
                left: 0,
                top: "5px",
                bottom: "10px",
                borderRightWidth: "5px",
                borderColor: "#FFDD00",
                height: "90px",
              }}
            />
            <Box sx={{}}>
              <Typography variant="h6" gutterBottom>
                Full Sangam
              </Typography>
              <Typography variant="body2">
                {data?.rates?.global?.full_sangam_1} Rs Ka{" "}
                {data?.rates?.global?.full_sangam_2} Rs
              </Typography>
            </Box>
          </Paper>
        </Box>
      </Box>
      <img src={img2} style={{ background: "#F6F6F6", width: "100%" }} />
    </>
  );
}

export default GameRates;
