import React, { useRef, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import img from "../../assets/images/section-top.svg";
import Header from "../../components/Header";
import CopyRight from "../../components/CopyRight";
import axios from "axios";
import { convertTimeFormat } from "../../api/api";
import { useParams } from "react-router-dom";
const StarlineChart = () => {
  const topRef = useRef(null);
  const bottomRef = useRef(null);
  const [weekdate, setWeekdate] = useState([]);
  let weeks = ["Sun", "Mon", "Tue", "Wed", "The", "Fri", "Sat"];
  var arr = [];
  const params = useParams();
  const getResults = async () => {
    try {
      let res = await axios.get(`/public/results?tag=starline`);
      let resData = res.data.data;
      //   console.log(resData)
      setData(resData);
    } catch (e) {
      //  console.log("e", e);
    }
  };
  const setData = (resdata) => {
    var data = [];
    var obj = {};
    for (var i = 0; i <= resdata.length - 1; i++) {
      var date = new Date(resdata[i].createdAt);
      date =
        date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
      var res1 =
        resdata[i].open_result !== undefined
          ? resdata[i].open_result
          : "*** - *";
      var time = convertTimeFormat(resdata[i].market.close_time);

      obj[date] = `${res1}` + `_` + `${time}`;
      //  console.log(obj)
      data.push(obj);
      obj = {};
    }
    setWeekdate(data);
    return;
  };

  const getStarDates = (day) => {
    const today = new Date();
    const currentDay = today.getDay();
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - day); // Set to Monday of the week
    var start = formatDate(startOfWeek);
    console.log(start);
    return start;
  };
  const getallDates = (day, weekIndex) => {
    const today = new Date();
    const currentDay = today.getDay();
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - currentDay + (day - weekIndex * 7)); // Set to Monday of the week

    var start = formatDate(startOfWeek);
    return start;
  };
  const formatDate = (date) => {
    if (date instanceof Date && !isNaN(date)) {
      const options = { year: "numeric", month: "numeric", day: "numeric" };
      var newdate = date.toLocaleDateString(options);
      newdate =
        date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
      return newdate;
    } else {
      return ""; // Return empty string if date is invalid
    }
  };

  const funcs = (item, time) => {
    for (var i = 0; i <= weekdate.length - 1; i++) {
      var obj = weekdate[i];
      var keys;
      var vals;
      var newTime;
      Object.entries(obj).map(([key, val], i) => {
        keys = key;
        var parts = val.split("_");
        vals = parts[0];
        newTime = parts[1];
      });

      if (item == keys && newTime == time) {
        //  console.log(weekdate)

        var d = getCss(vals);
        return d;
      }
    }
    return getCss("*** - ** - ***");
  };
  const getCss = (vals) => {
    var part = vals.split(" - ");
    var n = Math.abs(Number(part[1][0]) - Number(part[1][1]));
    var s = false;
    if (n == 0 || n == 5) {
      s = true;
    }
    return (
      <div className={s == true ? "red" : "black"}>
        <p>{part[0]} </p>
        <p className="Wet">{part[1][0]}</p>
      </div>
    );
  };
  for (var i = 1; i <= 7; i++) {
    var date = getallDates(i, 1);
    var day = new Date(date).getDay();
    arr.push(weeks[day]);
    // console.log(day)
  }
  const scrollToTop = () => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToBottom = () => {
    bottomRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const goBack = () => {
    window.history.back();
  };
  useEffect(() => {
    getResults();
  }, []);
  return (
    <>
      <Header />
      <div ref={topRef}></div>
      <img
        src={img}
        style={{ background: "#F6F6F6", width: "100%" }}
        alt="Top Section"
      />
      <Box sx={{ background: "#F6F6F6", marginTop: "-7px", pb: 5 }}>
        <Container maxWidth="lg">
          <Typography variant="h4" component="h1" align="center" gutterBottom>
            Starline Chart
          </Typography>
          <Typography variant="subtitle1" align="center" gutterBottom>
            Starline Chart Satta Matka Record Old History Historical Data
            Bracket Results Chart Online Live Book Digits Numbers
          </Typography>
          <Box sx={{ textAlign: "center", mb: 2 }}>
            <Button variant="outlined" sx={{ m: 1 }} onClick={scrollToBottom}>
              Go To Bottom
            </Button>
          </Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ border: "1px solid #FFDD00", fontWeight: "bold" }}
                  >
                    {" "}
                    <p>Date</p>
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{ border: "1px solid #FFDD00", fontWeight: "bold" }}
                  >
                    10:00 AM
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: "1px solid #FFDD00", fontWeight: "bold" }}
                  >
                    11:00 AM
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: "1px solid #FFDD00", fontWeight: "bold" }}
                  >
                    12:00 PM
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: "1px solid #FFDD00", fontWeight: "bold" }}
                  >
                    01:00 PM
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: "1px solid #FFDD00", fontWeight: "bold" }}
                  >
                    02:00 PM
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: "1px solid #FFDD00", fontWeight: "bold" }}
                  >
                    03:00 PM
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: "1px solid #FFDD00", fontWeight: "bold" }}
                  >
                    04:00 PM
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{ border: "1px solid #FFDD00", fontWeight: "bold" }}
                  >
                    05:00 PM
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: "1px solid #FFDD00", fontWeight: "bold" }}
                  >
                    06:00 PM
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: "1px solid #FFDD00", fontWeight: "bold" }}
                  >
                    07:00 PM
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: "1px solid #FFDD00", fontWeight: "bold" }}
                  >
                    08:00 PM
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: "1px solid #FFDD00", fontWeight: "bold" }}
                  >
                    09:00 PM
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: "1px solid #FFDD00", fontWeight: "bold" }}
                  >
                    10:00 PM
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from({ length: 69 }).map((_, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        align="center"
                        sx={{ border: "1px solid #FFDD00", fontWeight: "bold" }}
                      >
                        {` `} <br />
                        {getStarDates(index)} <br />
                        {` `}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ border: "1px solid #FFDD00", fontWeight: "bold" }}
                      >
                        {funcs(getStarDates(index), "10:00 AM")}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ border: "1px solid #FFDD00", fontWeight: "bold" }}
                      >
                        {funcs(getStarDates(index), "11:00 AM")}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ border: "1px solid #FFDD00", fontWeight: "bold" }}
                      >
                        {funcs(getStarDates(index), "12:00 PM")}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ border: "1px solid #FFDD00", fontWeight: "bold" }}
                      >
                        {funcs(getStarDates(index), "1:00 PM")}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ border: "1px solid #FFDD00", fontWeight: "bold" }}
                      >
                        {funcs(getStarDates(index), "2:00 PM")}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ border: "1px solid #FFDD00", fontWeight: "bold" }}
                      >
                        {funcs(getStarDates(index), "3:00 PM")}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ border: "1px solid #FFDD00", fontWeight: "bold" }}
                      >
                        {funcs(getStarDates(index), "4:00 PM")}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ border: "1px solid #FFDD00", fontWeight: "bold" }}
                      >
                        {funcs(getStarDates(index), "5:00 PM")}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ border: "1px solid #FFDD00", fontWeight: "bold" }}
                      >
                        {funcs(getStarDates(index), "6:00 PM")}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ border: "1px solid #FFDD00", fontWeight: "bold" }}
                      >
                        {funcs(getStarDates(index), "7:00 PM")}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ border: "1px solid #FFDD00", fontWeight: "bold" }}
                      >
                        {funcs(getStarDates(index), "8:00 PM")}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ border: "1px solid #FFDD00", fontWeight: "bold" }}
                      >
                        {funcs(getStarDates(index), "9:00 PM")}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ border: "1px solid #FFDD00", fontWeight: "bold" }}
                      >
                        {funcs(getStarDates(index), "10:00 PM")}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ textAlign: "center", mt: 3 }}>
            <Button variant="outlined" sx={{ m: 1 }} onClick={goBack}>
              Back
            </Button>
            <Button variant="outlined" sx={{ m: 1 }} onClick={scrollToTop}>
              Go To Top
            </Button>
          </Box>
        </Container>
      </Box>
      <div ref={bottomRef}></div>
      <CopyRight />
    </>
  );
};

export default StarlineChart;
