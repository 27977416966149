import React, { useEffect, useState } from "react";
import img from "../assets/images/section-top.svg";
import img2 from "../assets/images/section-bottom.svg";

import {
  Box,
  Typography,
  Paper,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { styled } from "@mui/system";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import axios from "axios";
import { convertTimeFormat, results } from "../api/api";

const CustomButton = styled(Button)(({ theme }) => ({
  border: "0.094rem solid #fab028",
  color: "#000",
  "&:hover": {
    backgroundColor: "#fab028",
    borderColor: "#fab028",
    color: "#fff",
  },
}));

function Games({apk}) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getResults = async () => {
    try {
        let res = await axios.get(
            `/public/markets?tag=global`
        );
        let resData = res.data.data;
        // console.log(resData)
        setData(resData)
    } catch (e) {
        console.log("e", e);
    }
};
useEffect(()=>{
    getResults()
},[])

  return (
    <>
      <img src={img} style={{ background: "#F6F6F6", width: "100%" }} />
      <Box
        sx={{
          background: "#F6F6F6",
          marginTop: "-7px",
          padding: "20px",
        }}
      >
        <Box
          sx={{
            width: "70%",
            margin: "auto",
          }}
        >
          <Typography variant="h4" style={{ fontWeight: "700" }} gutterBottom>
            Game
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            width: "90%",
            margin: "auto",
            gap: "20px",
          }}
        >
          {data.map((item, index) => (
            <Paper
              key={index}
              sx={{ padding: "20px", width: "350px", position: "relative" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle1">
                  {item.name} {convertTimeFormat(item.open_time)}
                  <IconButton size="small" onClick={handleClickOpen} style={{marginLeft:'12px'}}>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Typography>
                <Typography variant="caption">{convertTimeFormat(item.close_time)}</Typography>
              </Box>
              <Typography variant="h5" sx={{ my: 2 }}>
              {results(item.open_panna,item.open_digit,item.close_digit,item.close_panna)}
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Link to={`/jodichart/${item._id}`}>
                  <CustomButton variant="outlined" size="small">
                    Jodi Chart
                  </CustomButton>
                </Link>
                <Link to={`/panachart/${item._id}`}>
                  <CustomButton variant="outlined" size="small">
                    Pana Chart
                  </CustomButton>
                </Link>
                <IconButton style={{ color: "#fab028" }} onClick={()=>window.open(apk)}>
                  <PlayArrowIcon />
                </IconButton>
              </Box>
            </Paper>
          ))}
        </Box>
      </Box>
      <img src={img2} style={{ background: "#F6F6F6", width: "100%" }} />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Starline 11:30 AM
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#fab028",
              padding: "5px",
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                padding: "10px",
                borderRadius: "5px 0 0 5px",
                flex: 2,
              }}
            >
              <Typography variant="subtitle2" style={{ color: "#fff" }}>
                Result Time
              </Typography>
            </Box>
            <Box
              sx={{
                textAlign: "center",
                backgroundColor: "#fab028",
                padding: "10px",
                borderRadius: "0 5px 5px 0",
                flex: 2,
              }}
            >
              <Typography variant="subtitle2" style={{ color: "#fff" }}>
                Bid Last Time
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#fff",
              padding: "10px",
              border: "1px solid #fab028",
              width: "400px",
            }}
          >
            <Box>
              <Typography variant="h6" style={{ color: "#000" }}>
                11:30 AM
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" style={{ color: "#000" }}>
                11:25 AM
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Games;
