import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home'
import JodiChart from './pages/JodiChart';
import ResultJodiChart from './pages/Result Chart/JodiChart';
import ResultPanaChart from './pages/Result Chart/PanaChart';
import axios from 'axios';
import StarlineChart from './pages/Result Chart/StarlineChart';
axios.defaults.baseURL = "https://server.kalyanmarketonlinematkaplay.com/";
//axios.defaults.headers["Content-Type"] = "application/json";



function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/chart" element={<JodiChart/>} />
        <Route path="/starlineChart" element={<StarlineChart/>} />
        <Route path="/jodichart/:id" element={<ResultJodiChart/>} />
        <Route path="/panachart/:id" element={<ResultPanaChart/>} />    
      </Routes>
    </Router>
  );
}
export default App;
