import React from 'react';
import { Box, Typography, Link, Container, Divider } from '@mui/material';
import img from '../assets/images/section-top.svg';
import logo from '../assets/images/kalyanlogo.png';
import age from '../assets/images/18plus.svg';

function Footer () {
  return (
    <>
      <img src={img} style={{ background: '#F6F6F6', width: '100%' }} alt="Top Section" />
      <Box component="footer" sx={{ py: 3, mt: 'auto', backgroundColor: '#f5f5f5', textAlign: 'center', marginTop: '-7px' }}>
        <Container maxWidth="lg">
          <Box sx={{ mb: 2 }}>
            <img src={logo} style={{ width: '150px', borderRadius: '20px' }} alt="Logo" />
          </Box>
          <img src={age} style={{ width: '40px', borderRadius: '20px' }} alt="18+ Age Limit" />
          <Typography variant="body2" color="#000">
            Players need to be 18+ in order to register. Underage gambling is prohibited.
          </Typography>
          {/* <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', gap: 4 }}>
            <Link href="https://www.begambleaware.org" target="_blank" rel="noopener" color="inherit" sx={{ display: 'block' }}>
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5a/BeGambleAware.org_Logo.svg/1200px-BeGambleAware.org_Logo.svg.png" alt="BeGambleAware" style={{ height: 30 }} />
            </Link>
            <Link href="https://www.gamblingtherapy.org" target="_blank" rel="noopener" color="inherit" sx={{ display: 'block' }}>
              <img src="https://www.gamblingtherapy.org/wp-content/uploads/2021/04/GT_Logo_new.png" alt="Gambling Therapy" style={{ height: 30 }} />
            </Link>
          </Box> */}
          <Typography variant="body2" color="#000" sx={{ mt: 3 }}>
            Our website is operated by Sara International, a company established under the law of Isle of Man, with registered address at 1-10 Ballanoa Meadow IM4-2HT, Isle Of Man, and having its gaming sublicense issued by Isle of Man e-Gaming and all rights to operate the gaming software worldwide.
          </Typography>
          <Divider sx={{ my: 3 }} />
          <Typography variant="body2" color="textSecondary">
            Copyright © 2024 Kalyan Market. All Rights Reserved
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
