import React from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Questions() {
  const faqs = [
    { question: 'What is Online Kalyan ?', answer: 'Online Kalyan is a Satta Matka Gaming App where you can play games and win jackpot.' },
    { question: 'What games are available on Online Kalyan ?', answer: 'Provide information about available games.' },
    { question: 'Does Online Kalyan have a license?', answer: 'Provide information about licensing.' },
    { question: 'What is the minimum deposit and withdrawal amount?', answer: 'Provide information about minimum deposit and withdrawals.' },
    { question: 'Why choose Online Kalyan among other providers?', answer: 'Provide reasons to choose Online Kalyan over other providers.' },
  ];

  return (
    <Box sx={{ marginTop: '-7px', padding: '20px' }}>
      <Box sx={{ width: {xs:'90%',md:'70%',sm:'90%'}, margin: 'auto', textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Frequently asked <span style={{ fontWeight: '700' }}>questions?</span>
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: {xs:'90%',md:'70%',sm:'90%'}, margin: 'auto', gap: '20px' }}>
        <Box sx={{ width: '100%' }}>
          {faqs.map((item, index) => (
            <React.Fragment key={index}>
              <Accordion
                sx={{
                  boxShadow: 'none',
                  '&:before': {
                    display: 'none',
                  },
                  '&.Mui-expanded': {
                    '& .MuiAccordionSummary-root': {
                      color: '#fab028',
                    },
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}a-content`}
                  id={`panel${index}a-header`}
                  sx={{
                    flexDirection: 'row-reverse',
                    '& .MuiAccordionSummary-content': {
                      marginLeft: '10px',
                    },
                  }}
                >
                  <Typography>{item.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ marginLeft: '30px' }}>{item.answer}</Typography>
                </AccordionDetails>
              </Accordion>
              {index < faqs.length - 1 && <Divider sx={{ margin: '10px 0' }} />}
            </React.Fragment>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default Questions;
