import React from 'react';
import { Box, Typography, Link, Container, Divider } from '@mui/material';
import img from '../assets/images/section-top.svg';

function CopyRight () {
  return (
    <>
      <img src={img} style={{ background: '#F6F6F6', width: '100%' }} alt="Top Section" />
      <Box component="footer" sx={{ py: 3, mt: 'auto', backgroundColor: '#f5f5f5', textAlign: 'center', marginTop: '-7px' }}>
        <Container maxWidth="lg">        
          <Divider sx={{ my: 2 }} />
          <Typography variant="body2" color="textSecondary">
            Copyright © 2024 Kalyan Market. All Rights Reserved
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default CopyRight;
