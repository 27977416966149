const results = (openPana, openDigit, closeDigit, closePana)=> {
    let data = "";
    if (openPana === "-") {
        data = "***";
    } else {
        data = openPana;
    }
    data = data + "-";
    if (openDigit === "-") {
        data = data + '*';
    } else {
        data = data + openDigit;
    }

    if (closeDigit === "-") {
        data = data + '*';
    } else {
        data = data + closeDigit;
    }
    data = data + "-";
    if (closePana === "-") {
        data = data + "***";
    } else {
        data = data + closePana;
    }

    return data;
}
const results2 = (openPana, openDigit, closeDigit, closePana)=> {
    let data = "";
    if (openPana === "-") {
        data = "***";
    } else {
        data = openPana;
    }
    data = data + "-";
    if (openDigit === "-") {
        data = data + '*';
    } else {
        data = data + openDigit;
    }

    return data;
}
const convertTimeFormat = (inputTime) =>{
// console.log('first',inputTime)
    const date = new Date(`2000-01-01T${inputTime}:00`);
  
    const hours = date.getHours();
    const minutes = date.getMinutes();
  
    
    const formattedHours = hours % 12 || 12;
  
    
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  
    // Determine if it's AM or PM
    const period = hours < 12 ? "AM" : "PM";
  
   
    const formattedTime = `${formattedHours}:${formattedMinutes} ${period}`;
 // console.log('first',formattedTime)
    return formattedTime;
  }
export {results,results2,convertTimeFormat}
