import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import img from '../assets/images/kalyanlogo.png';
import { Link as RouterLink } from 'react-router-dom';

const StyledButton = styled(Button)({
  color: '#000',
  border: '0.094rem solid #fab028',
  textTransform: 'none',
  borderRadius: "5px",
  padding: '7px 20px',
  transition: '0.5s',
  '&:hover': {
    backgroundColor: '#fab028',
    color: '#fff',
    boxShadow: '0px 15px 35px rgba(255, 215, 0, 0.3)',
  },
});

const StyledLink = styled(RouterLink)({
  color: '#000',
  textDecoration: 'none',
  '&:hover': {
    color: '#fab028',
  },
  '&:active': {
    color: '#fab028',
  },
});

const Header = ({apk}) => {
  return (
    <AppBar position="static" style={{ background: '#F6F6F6', boxShadow: 'none' }}>
      <Toolbar sx={{ margin: {xs:'0 10px',md:'0 100px',sm:'0 10px'} }}>
        <img src={img} style={{ width: '100px',height:'30px', borderRadius: '60px' }} alt="Logo" />
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', gap: {xs:'20px',md:'44px',sm:'44px'} }}>
          <StyledLink to="/">Home</StyledLink>
          <StyledLink to="/chart">Charts</StyledLink>
        </Box>
        <StyledButton onClick={()=>window.open(apk)}>
          Download App
        </StyledButton>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
