import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import img from "../assets/images/banner-shot.webp";
import { styled } from '@mui/system';
import { Button, Typography } from '@mui/material';
import AndroidIcon from '@mui/icons-material/Android';
import GameRates from '../pages/GameRates';
import Games from '../pages/Games';
import Questions from '../pages/Questions';


const StyledButton = styled(Button)({
  position: 'relative',
  color: '#666666',
  fontSize: '28px',
  background: '#fab028',
  textTransform: 'none',
  borderRadius: '50px',
  padding: '5px',
  transition: '0.5s',
  width: '100%',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '0',
    left: '-100%',
    width: '10%',
    height: '100%',
    background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.6), transparent)',
    animation: 'move 6s linear infinite',
  },
  '@keyframes move': {
    '0%': {
      left: '-100%',
    },
    '100%': {
      left: '100%',
    },
  },
  '&:hover': {
    backgroundColor: '#F4C400',
  },
});

const StyledTypography = styled(Typography)({
  marginTop: '20px',
  fontSize: '18px',
});

const StyledButtonGroup = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '20px',
});

function Content({data2}) {

  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          background: '#F6F6F6',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '20px',
          paddingBottom:'40px'

        }}
      >
        <img src={img} style={{ width: '50%' }} alt="Banner" />
        <StyledButton startIcon={<AndroidIcon />} onClick={()=>window.open(data2.app_link)}>
          Download App
        </StyledButton>
        <StyledTypography variant="h1" style={{ fontSize: '28px', fontWeight: '700' }}>
         <a href={`tel:${data2.mobile}`}> +91-{data2.mobile}</a>
        </StyledTypography>
        <StyledButtonGroup>
          <Button
            variant="contained"
            style={{
              marginRight: '10px',
              background: 'linear-gradient(63deg, #f44336 0%, rgba(255,197,98,1) 100%)',
              color: '#fff',
            }}
            onClick={() => window.location.href = `tel:${data2.mobile}`}
          >
            Call Now
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{
              backgroundColor: '#25d366',
              color: '#fff',
            }}
            onClick={() => window.location.href = `https://api.whatsapp.com/send?phone=${data2.whatsapp}`}

          >
            Whatsapp
          </Button>
        </StyledButtonGroup>
      </Box>
      <GameRates/>
      <Games apk={data2.app_link}/>
      <Questions/>
    </>
  );
}

export default Content;
